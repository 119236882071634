<template>
  <div
    class="feed-priority-list"
    @dragenter.prevent
    @dragover.prevent
  >
    <div
      v-for="entry in sortedEntries"
      :key="JSON.stringify(entry)"
      class="feed-priority-list__feed"
      draggable="true"
      @dragstart="onDragStart($event, entry)"
      @drop="onDrop($event, entry)"
    >
      <Icon
        name="burger-menu"
      />
      {{ entry.feed }}
    </div>
  </div>
</template>

<script>
import { orderBy, map } from 'lodash';
import { computed } from 'vue';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    entries: {
      type: Array,
      required: true,
    },
  },
  emits: {
    'update:entries': {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const sortedEntries = computed(() => orderBy(props.entries, 'priority'));

    const onDragStart = (event, sourceEntry) => {
      event.dataTransfer.setData('source-entry', JSON.stringify(sourceEntry));
    };
    const onDrop = (event, destinationEntry) => {
      const sourceEntry = JSON.parse(event.dataTransfer.getData('source-entry'));
      if (sourceEntry === destinationEntry) return;
      const updatedEntries = map(props.entries, (entry) => {
        if (entry.feed === sourceEntry.feed) {
          return { ...destinationEntry, feed: entry.feed };
        }
        if (entry.feed === destinationEntry.feed) {
          return { ...sourceEntry, feed: entry.feed };
        }
        return entry;
      });
      emit('update:entries', updatedEntries);
    };

    return {
      sortedEntries,
      onDragStart,
      onDrop,
    };
  },
};
</script>

<style lang="scss">
.feed-priority-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 4px;

  &__header {
    color: #A9A9A9;
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  &__feed {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px;
    cursor: move;
    user-select: none;
  }
}
</style>
