<template>
  <div
    :class="['events-sidebar', isEventsSidebarActive && 'active']"
  >
    <div class="sidebar-header">
      <span>{{ title }}</span>
      <Icon
        name="close"
        @click="closeSidebar"
      />
    </div>
    <div class="sidebar-content">
      <ColumnsSidebar />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import Icon from '@/components/common/Icon';
import ColumnsSidebar from '@/components/bet-ticker/sidebar/ColumnsSidebar';

export default {
  components: {
    Icon,
    ColumnsSidebar,
  },
  setup() {
    const store = useStore();
    const isEventsSidebarActive = computed(() => store.getters.isEventsSidebarActive);
    const title = computed(() => 'Table Columns');
    const closeSidebar = () => store.dispatch('setIsEventsSidebarActive', false);

    return {
      title,
      isEventsSidebarActive,
      closeSidebar,
    };
  },
};
</script>
<style lang="scss">
.events-sidebar {
  width: 320px;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #fff;
  transition: right .5s;
  z-index: $betTickerSidebarZIndex;
  display: none;

  &.active {
    right: 0;
    display: block;
  }
  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    padding: 16px;
    border-bottom: 1px solid $gray400;
    border-left: 1px solid $gray400;
    background-color: $white;
    font-weight: 600;
    font-size: 16px;
    .icon {
      width: 8px;
      height: 8px;
      cursor: pointer;
    }
  }
  .sidebar-content {
    height: calc(100% - 55px);
    border-left: 1px solid $gray400;
  }
}
</style>
